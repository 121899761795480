import {put, select, take, fork, call, all} from 'redux-saga/effects';
import {efServiceAssignmentTypes, ACEPartner, TemporaryRestriction, apmContractPartnerContractStatusTypes, apmACEPartnerServiceTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceCaseHelpers from '../../service-cases/sagas/serviceCaseHelpers';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as sapActionTypes from '../sapActionTypes';
import isTemporaryRestrictionActive from '../../contract-partners/isTemporaryRestrictionActive';

const filterSAPMapContractPartnersBySearchQuery = function* filterSAPMapContractPartnersBySearchQuery(
    serviceCaseId,
    serviceAssignmentLineNo,
    searchQuery,
    isVBASearch = false,
) {
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const {contractPartners} = yield select(state => state.contractPartners);
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const {listOfPreviousPartners} = serviceAssignment;

    yield put({
        type: sapActionTypes.SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {serviceAssignmentId, contractPartnerRecommendationDTOs: []},
    });

    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISRESTService = serviceManager.loadService('arcGISRESTService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-pickup');

    const {pickupLocation, destination} = serviceAssignment;
    if (!serviceAssignment || !pickupLocation || !destination) return;

    if (!arcGISMap) return;

    const sapContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-locations');
    const sapContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-service-areas');
    const sapContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sap-contract-partner-routes');

    if (!sapContractPartnerLocationsLayer
        && !sapContractPartnerServiceAreasLayer
        && !sapContractPartnerRoutesLayer) return;

    yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);

    const searchableContractPartners = Object.values(contractPartners).filter(contractPartner => {
        // if it's a VBA search, only VBA partners can be selected
        if (isVBASearch) {
            return contractPartner?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY;
        }
        // only ACTIVE contract partners can be selected - APM should be a source of truth, see ACEMS-997
        return contractPartner?.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE;
    });

    yield put({
        type: sapActionTypes.SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });


    yield fork(
        fetchRequest,
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        sapContractPartnerLocationsLayer.filterFeaturesByAttribute,
        {
            where: `(name LIKE '%${searchQuery}%' OR address LIKE '%${searchQuery}%') AND contractStatus = '${isVBASearch ? apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY : apmContractPartnerContractStatusTypes.ACTIVE}' AND contractPa IN (${searchableContractPartners
                .map(contractPartner => `'${contractPartner.id}'`)
                .join(', ')})`,
            featureCount: 20,
            referentialPoint: pickupLocation,
            returnRoutes: true,
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            returnLocationFromAttribute: 'address',
            keepPreviousResults: false,
        },
    );

    const filterPointFeaturesResponseAction = yield take([
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);

    if (filterPointFeaturesResponseAction.error) {
        yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
        return;
    }

    const {response} = filterPointFeaturesResponseAction.payload;
    let {featureDTOs: contractPartnerRecommendationDTOs = []} = response;

    // filter Contract Partner base features (points) by matching available services
    if (contractPartnerRecommendationDTOs.length > 0) {
        contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
            .filter(contractPartnerDTO => {
                const contractPartner = contractPartners[contractPartnerDTO['contractPartnerId']];
                if (!contractPartner) return false;

                // if the contract partner does not support pickup as a service, display warning
                if (!contractPartner?.services?.find(service => {
                    return service.serviceType === apmACEPartnerServiceTypes.PICKUP;
                })) {
                    contractPartnerDTO['isPickupServiceSupported'] = true;
                }

                return true;
            });
    }


    // if current contract partner is not in recommended set, render it in layers
    const currentContractPartnerStatus = serviceAssignment?.acePartner?.id
        && contractPartners
        && contractPartners[serviceAssignment.acePartner.id]
        ? contractPartners[serviceAssignment.acePartner.id].contractStatus || null : null;
    // if it's a VBA search, do not include the recommended ACTIVE contract partner, and vice versa
    /* eslint-disable max-len */
    const shouldDisplayRecommendedCP = !(isVBASearch && currentContractPartnerStatus === apmContractPartnerContractStatusTypes.ACTIVE)
        && !(!isVBASearch && currentContractPartnerStatus !== apmContractPartnerContractStatusTypes.ACTIVE);
    /* eslint-enable max-len */
    if (serviceAssignment.acePartner
        && !contractPartnerRecommendationDTOs.find(contractPartnerDTO => {
            return contractPartnerDTO['contractPartnerId'] === serviceAssignment.acePartner.id;
        })
        && shouldDisplayRecommendedCP) {
        yield fork(
            fetchRequest,
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
            sapContractPartnerLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa = '${serviceAssignment.acePartner.id}'`,
                referentialPoint: pickupLocation,
                returnRoutes: true,
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                returnLocationFromAttribute: 'address',
                keepPreviousResults: true,
            },
        );

        const filterPointFeaturesResponseAction = yield take([
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
            sapActionTypes.FILTER_SAP_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
        ]);

        if (!filterPointFeaturesResponseAction.error) {
            const {response} = filterPointFeaturesResponseAction.payload;
            const {featureDTOs: currentContractPartnerRecommendationDTOs = []} = response;
            contractPartnerRecommendationDTOs.push(...currentContractPartnerRecommendationDTOs);

            // re-sort by distance
            contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
                .sort((locationA, locationB) => {
                    if (!locationA['routeToDamageLocation']
                    || !locationB['routeToDamageLocation']) return 0;
                    const {routeToDamageLocation: locationARoute} = locationA;
                    const {routeToDamageLocation: locationBRoute} = locationB;
                    return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
                });
        }
    }

    // filter service areas for recommended contract partners
    let matchingContractPartnerIds = [];
    if (contractPartnerRecommendationDTOs.length > 0) {
        yield fork(
            fetchRequest,
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST,
            sapContractPartnerServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartnerRecommendationDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO.contractPartnerId}'`)
                    .join(', ')})`,
                referentialPoint: pickupLocation,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED,
            sapActionTypes.FILTER_SAP_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            const {featureDTOs: contractPartnerServiceAreaDTOs} = response;

            // find service areas containing damage location
            matchingContractPartnerIds = contractPartnerServiceAreaDTOs
                .filter(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['containsDamageLocation'])
                .map(candidateContractPartnerServiceAreaDTO => {
                    return candidateContractPartnerServiceAreaDTO['contractPartnerId'];
                });
        }
    }

    // fetch temporary restrictions for recommended contract partners
    if (contractPartnerRecommendationDTOs.length > 0) {
        const restrictionsMap = yield call(partnerManagementService.getACEPartnersRestrictions, {
            acePartnerIds: contractPartnerRecommendationDTOs
                .map(contractPartnerRecommendationDTO => contractPartnerRecommendationDTO['contractPartnerId'])
                .filter(Boolean),
        });
        contractPartnerRecommendationDTOs.forEach(contractPartnerRecommendationDTO => {
            if (restrictionsMap && restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]) {
                const temporaryRestrictions = new Map();
                restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]
                    .forEach(restriction => {
                        const temporaryRestriction = new TemporaryRestriction().fromDTO(restriction);
                        if (isTemporaryRestrictionActive(temporaryRestriction)) {
                            temporaryRestrictions.set(temporaryRestriction.id, temporaryRestriction);
                        }
                    });
                contractPartnerRecommendationDTO.temporaryRestrictions = temporaryRestrictions;
            }
        });
    }

    // patch contractPartnerRecommendationDTOs (from ArcGIS) with APM data
    contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
        const apmContractPartner = contractPartners[contractPartnerDTO['contractPartnerId']];
        if (!apmContractPartner) return contractPartnerDTO;

        return apmContractPartner.patchArcGISDataIntoDTO(contractPartnerDTO);
    });

    // find candidate contract partner based on distance from damage location
    let nearestRoute;
    let recommendedContractPartnerDTO;
    if (shouldDisplayRecommendedCP) {
        (matchingContractPartnerIds.length === 0
            ? contractPartnerRecommendationDTOs
            : contractPartnerRecommendationDTOs.filter(contractPartnerDTO => {
                return matchingContractPartnerIds.includes(contractPartnerDTO.contractPartnerId);
            })
        ).forEach(contractPartnerDTO => {
            const routeToDamageLocationDTO = contractPartnerDTO['routeToDamageLocation'];
            if (!routeToDamageLocationDTO) return;
            if ((routeToDamageLocationDTO['totalKilometers'] < nearestRoute || !recommendedContractPartnerDTO)
                && !listOfPreviousPartners?.includes(contractPartnerDTO.contractPartnerId)
            ) {
                recommendedContractPartnerDTO = contractPartnerDTO;
                nearestRoute = routeToDamageLocationDTO['totalKilometers'];
            }
        });
    }

    // calculate routes from CP to towing destination
    // route calculation: pickup location -> towing destination -> CP base
    if (contractPartnerRecommendationDTOs.length > 0) {
        const routes = yield call(
            arcGISRESTService.getBulkRoutes,
            contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
                if (!contractPartnerDTO.location?.latitude || !contractPartnerDTO.location?.longitude) return null;
                return {
                    startingPoint: {
                        longitude: destination.longitude,
                        latitude: destination.latitude,
                    },
                    destination: {
                        longitude: contractPartnerDTO.location.longitude,
                        latitude: contractPartnerDTO.location.latitude,
                    },
                };
            }),
            // In order to avoid confusion during CP selection, route is rendered using TRUCK_TRAVEL_TIME instead of
            // TRUCK_SHORTEST_DISTANCE. CP list, auto-selection and other logic still uses TRUCK_SHORTEST_DISTANCE,
            // while driver will be driving on rendered route according to this ticket:
            // https://computerrock.atlassian.net/browse/ACELEA-3193
            arcGISTravelModeTypes.TRUCK_TRAVEL_TIME,
        );
        contractPartnerRecommendationDTOs.forEach((contractPartnerDTO, index) => {
            contractPartnerDTO['routeToPickupLocation'] = routes[index];
        });
    }

    // render routes for recommended contract partners
    if (contractPartnerRecommendationDTOs.length > 0) {
        yield call(sapContractPartnerRoutesLayer.setFeatures, {
            features: contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
                const routeToPickupLocation = contractPartnerDTO['routeToPickupLocation'];
                if (!routeToPickupLocation) return null;
                return {
                    attributes: {
                        'FID': contractPartnerDTO['featureId'],
                        'contractPa': contractPartnerDTO['contractPartnerId'],
                        'Name': contractPartnerDTO['contractPartnerName'],
                    },
                    geometry: routeToPickupLocation.geometry,
                };
            }).filter(Boolean),
        });
    }

    // select features for current contract partner or the recommended one
    const selectedContractPartner = serviceAssignment.acePartner?.id
        ? contractPartners[serviceAssignment.acePartner.id] || null : null;
    /* eslint-disable max-len */
    const selectedContractPartnerId = selectedContractPartner
        && ((isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
            || (!isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE))
        ? selectedContractPartner.id
        : (recommendedContractPartnerDTO ? recommendedContractPartnerDTO['contractPartnerId'] : null);
    if (selectedContractPartnerId) {
        yield all([
            call(sapContractPartnerLocationsLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(sapContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(sapContractPartnerRoutesLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
        ]);
    }

    // save recommended contract partner as selected if no contract partner was previously set
    if (!serviceAssignment.acePartner && recommendedContractPartnerDTO) {
        const recommendedContractPartner = new ACEPartner()
            .fromDTO(contractPartners[recommendedContractPartnerDTO.id] || null);
        yield* updateServiceAssignment({
            caller: 'LOAD_SAP_CONTRACT_PARTNER_RECOMMENDATIONS',
            assignmentType: efServiceAssignmentTypes.PICKUP,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...recommendedContractPartner,
                    ...(recommendedContractPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...recommendedContractPartner.businessContactDetails,
                                phoneNo: recommendedContractPartner?.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                },
                assignmentText: null,
            },
        });

        // set persistence state back to PENDING
        yield* serviceCaseHelpers.setPersistencePending(serviceCaseId);
    }

    yield put({
        type: sapActionTypes.SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            contractPartnerRecommendationDTOs,
            recommendedContractPartnerId: recommendedContractPartnerDTO
                ? recommendedContractPartnerDTO['contractPartnerId'] : null,
        },
    });

    yield put({
        type: sapActionTypes.SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });

    // display layers
    sapContractPartnerLocationsLayer.show();
    sapContractPartnerServiceAreasLayer.show();
    sapContractPartnerRoutesLayer.show();
    yield* serviceCaseHelpers.setPersistenceReady(serviceCaseId);
};

export default filterSAPMapContractPartnersBySearchQuery;
