import {produce} from 'immer';
import {Location, efLocationTypes, RentalCarStation, efServiceAssignmentTypes, ACEPartner, QualityManagementFeedback, persistenceStates, apsSurchargeRateTypes} from '@ace-de/eua-entity-types';
import * as serviceAssignmentActionTypes from './serviceAssignmentActionTypes';
import * as savActionTypes from '../service-assignments-vehicle/savActionTypes';
import * as saaActionTypes from '../service-assignments-accommodation/saaActionTypes';
import * as sastActionTypes from '../service-assignments-short-trip/sastActionTypes';
import * as sarcActionTypes from '../service-assignments-rental-car/sarcActionTypes';
import * as sarjActionTypes from '../service-assignments-return-journey/sarjActionTypes';
import * as sasdActionTypes from '../service-assignments-substitute-driver/sasdActionTypes';
import * as samcActionTypes from '../service-assignments-medical-clearance/samcActionTypes';
import * as savrActionTypes from '../service-assignments-vehicle-return/savrActionTypes';
import * as savaActionTypes from '../service-assignments-vehicle-additional/savaActionTypes';
import * as sapActionTypes from '../service-assignments-pickup/sapActionTypes';
import * as satrActionTypes from '../service-assignments-trailer-return/satrActionTypes';
import * as samaActionTypes from '../service-assignments-medical-advice/samaActionTypes';
import createNewServiceAssignment from './createNewServiceAssignment';

const initialState = {
    serviceAssignments: {},
    rentalCarACEPartners: {},
    pickupLocationToTowingDestinationRoute: {},
    serviceProviders: {},
    arcGISNearbyPOIs: [],
    selectedPOIsCategory: '',
    errorCode: '',
    hasToResetAttachmentSelection: false,
    medicalAdviceServiceProviders: [],
    contractPartnerRecommendationsPersistenceState: persistenceStates.PENDING,
    serviceProviderRecommendationsPersistenceState: persistenceStates.PENDING,
    serviceAssignmentBudgets: {},
    serviceAssignmentBudgetMaxDurations: {},
    pickupPrices: {},
};

/**
 * Service assignments reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const serviceAssignmentReducer = produce((draft, action) => {
    switch (action.type) {
        case serviceAssignmentActionTypes.STORE_SERVICE_ASSIGNMENTS: {
            const {serviceAssignmentDTOs} = action.payload;
            serviceAssignmentDTOs.forEach(serviceAssignmentDTO => {
                const serviceAssignmentId = `${serviceAssignmentDTO.serviceCaseId}-${serviceAssignmentDTO.lineNo}`;
                const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
                if (!serviceAssignment) {
                    if (!Object.values(efServiceAssignmentTypes).includes(serviceAssignmentDTO.assignmentType)) return;

                    draft.serviceAssignments[serviceAssignmentId] = createNewServiceAssignment(
                        serviceAssignmentDTO.assignmentType,
                    ).fromDTO(serviceAssignmentDTO);
                    return;
                }
                draft.serviceAssignments[serviceAssignmentId] = serviceAssignment.fromDTO(serviceAssignmentDTO);
            });
            break;
        }

        case serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_PERSISTENCE_STATE: {
            const {serviceAssignmentId, persistenceState} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            if (serviceAssignment) {
                draft.serviceAssignments[serviceAssignmentId] = serviceAssignment.setPersistenceState(persistenceState);
            }
            break;
        }

        case savaActionTypes.SET_SAVA_SERVICE_LOCATION_CANDIDATES:
        case sastActionTypes.SET_SAST_DESTINATION_CANDIDATES:
        case sarjActionTypes.SET_SARJ_DESTINATION_CANDIDATES:
        case sasdActionTypes.SET_SASD_RELEVANT_LOCATION_CANDIDATES:
        case samcActionTypes.SET_SAMC_DAMAGE_LOCATION_CANDIDATES:
        case sarcActionTypes.SET_SARC_PICKUP_LOCATION_CANDIDATES:
        case sarcActionTypes.SET_SARC_DELIVERY_LOCATION_CANDIDATES:
        case sarcActionTypes.SET_SARC_DROP_OFF_LOCATION_CANDIDATES:
        case savrActionTypes.SET_SAVR_PICKUP_LOCATION_CANDIDATES:
        case savrActionTypes.SET_SAVR_DESTINATION_CANDIDATES:
        case sapActionTypes.SET_SAP_PICKUP_LOCATION_CANDIDATES:
        case sapActionTypes.SET_SAP_DESTINATION_CANDIDATES:
        case saaActionTypes.SET_SAA_REFERENCE_POSITION_CANDIDATES:
        case satrActionTypes.SET_SATR_PICKUP_LOCATION_CANDIDATES:
        case satrActionTypes.SET_SATR_DESTINATION_CANDIDATES:
        case saaActionTypes.SET_SAA_HOTEL_LOCATION_CANDIDATES:
        case serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_RELEVANT_LOCATION_CANDIDATES:
        {
            const {locationKey, arcGISLocationCandidateDTOs, serviceAssignmentId, searchQueryString} = action.payload;
            const {locationType = null} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment[`${locationKey}SearchQuery`] = searchQueryString;
            serviceAssignment[`${locationKey}Candidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new Location().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(locationType || efLocationTypes.SELECT_LOCATION);
                });
            break;
        }

        case serviceAssignmentActionTypes.SET_ACE_PARTNER_LOCATION_CANDIDATES: {
            const {arcGISLocationCandidateDTOs, serviceAssignmentId, searchQueryString} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['acePartnerLocationSearchQuery'] = searchQueryString;
            serviceAssignment['acePartnerLocationCandidates'] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new Location().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(efLocationTypes.SELECT_LOCATION);
                });
            break;
        }

        case serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_LOCATION_CANDIDATES: {
            const {arcGISLocationCandidateDTOs, serviceAssignmentId, searchQueryString} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment[`finalDestinationLocationSearchQuery`] = searchQueryString;
            serviceAssignment[`finalDestinationLocationCandidates`] = arcGISLocationCandidateDTOs
                .map(arcGISLocationCandidateDTO => {
                    const locationCandidate = new Location().fromDTO(arcGISLocationCandidateDTO);
                    return locationCandidate.setLocationType(efLocationTypes.SELECT_LOCATION);
                });
            break;
        }

        case serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_FINAL_DESTINATION_ROUTE: {
            const {routeToReferentialPoint, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment[`finalDestinationRoute`] = routeToReferentialPoint;
            break;
        }

        case serviceAssignmentActionTypes.SET_DAMAGE_LOCATION_TO_ACE_PARTNER_ROUTE: {
            const {routeToReferentialPoint, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.acePartner[`routeToDamageLocation`] = routeToReferentialPoint;
            break;
        }

        case sapActionTypes.RESET_SAP_PICKUP_LOCATION_CANDIDATES:
        case savrActionTypes.RESET_SAVR_PICKUP_LOCATION_CANDIDATES:
        case satrActionTypes.RESET_SATR_PICKUP_LOCATION_CANDIDATES: {
            const {serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['pickupLocationCandidates'] = [];
            break;
        }

        case sapActionTypes.RESET_SAP_DESTINATION_CANDIDATES:
        case savrActionTypes.RESET_SAVR_DESTINATION_CANDIDATES:
        case satrActionTypes.RESET_SATR_DESTINATION_CANDIDATES: {
            const {serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['destinationCandidates'] = [];
            break;
        }

        case serviceAssignmentActionTypes.RESET_RELEVANT_LOCATION_CANDIDATES: {
            const {serviceAssignmentId, locationKey} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment[`${locationKey}Candidates`] = [];
            break;
        }

        case sastActionTypes.SET_SAST_DESTINATION_CANDIDATE_DISTANCE: {
            const {distanceResidenceToDestination, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['destinationCandidateDistance'] = distanceResidenceToDestination;
            break;
        }

        case sarcActionTypes.SET_SARC_DELIVERY_LOCATION_CANDIDATE_DISTANCE: {
            const {distanceMemberLocationToDeliveryLocation, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['deliveryLocationCandidateDistance'] = distanceMemberLocationToDeliveryLocation;
            break;
        }

        case savrActionTypes.SET_SAVR_PICKUP_LOCATION_TO_DESTINATION_DISTANCE: {
            const {pickupLocationToDestinationDistance, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['pickupLocationToDestinationDistance'] = pickupLocationToDestinationDistance;
            break;
        }

        case savaActionTypes.SET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION: {
            const {arcGISReverseGeocodingLocationDTO, serviceAssignmentId, locationType} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            const reverseGeocodingServiceLocation = new Location().fromDTO(arcGISReverseGeocodingLocationDTO);
            serviceAssignment.reverseGeocodingServiceLocation = reverseGeocodingServiceLocation
                .setLocationType(locationType);
            break;
        }

        case savaActionTypes.RESET_SAVA_REVERSE_GEOCODING_SERVICE_LOCATION: {
            const {serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.reverseGeocodingServiceLocation = null;
            break;
        }

        case samcActionTypes.SET_SAMC_REVERSE_GEOCODING_DAMAGE_LOCATION: {
            const {arcGISReverseGeocodingLocationDTO, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            const reverseGeocodingDamageLocation = new Location().fromDTO(arcGISReverseGeocodingLocationDTO);
            serviceAssignment.reverseGeocodingDamageLocation = reverseGeocodingDamageLocation
                .setLocationType(efLocationTypes.SELECT_LOCATION);
            break;
        }

        case sasdActionTypes.SET_SASD_REVERSE_GEOCODING_RELEVANT_LOCATION: {
            const {arcGISReverseGeocodingLocationDTO, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            const reverseGeocodingRelevantLocation = new Location().fromDTO(arcGISReverseGeocodingLocationDTO);
            serviceAssignment.reverseGeocodingRelevantLocation = reverseGeocodingRelevantLocation
                .setLocationType(efLocationTypes.SELECT_LOCATION);
            break;
        }

        case savActionTypes.SET_SAV_TOWING_DESTINATION_RECOMMENDATIONS:
        case savaActionTypes.SET_SAVA_TOWING_DESTINATION_RECOMMENDATIONS: {
            const {serviceAssignmentId, towingDestinationRecommendationDTOs} = action.payload;
            const {recommendedTowingDestinationId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.towingDestinationRecommendations = towingDestinationRecommendationDTOs
                .map(towingDestinationDTO => new Location().fromDTO(towingDestinationDTO));
            serviceAssignment.recommendedTowingDestinationId = recommendedTowingDestinationId;
            break;
        }

        case savActionTypes.SET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID:
        case savaActionTypes.SET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID: {
            const {serviceAssignmentId, selectedTowingDestinationLocationId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.selectedTowingDestinationLocationId = selectedTowingDestinationLocationId;
            break;
        }

        case savActionTypes.RESET_SAV_SELECTED_TOWING_DESTINATION_LOCATION_ID:
        case savaActionTypes.RESET_SAVA_SELECTED_TOWING_DESTINATION_LOCATION_ID: {
            const {serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.selectedTowingDestinationLocationId = null;
            break;
        }

        case serviceAssignmentActionTypes.SET_ARCGIS_NEARBY_POIS: {
            const {arcGISLocationCandidateDTOs, selectedCategory} = action.payload;
            draft.arcGISNearbyPOIs = arcGISLocationCandidateDTOs
                .map(locationCandidateDTO => new Location().fromDTO(locationCandidateDTO));
            draft.selectedPOIsCategory = selectedCategory;
            break;
        }
        case serviceAssignmentActionTypes.RESET_ARCGIS_NEARBY_POIS_RESULTS: {
            draft.arcGISNearbyPOIs = [];
            draft.selectedPOIsCategory = '';
            break;
        }

        case sapActionTypes.SET_SAP_CONTRACT_PARTNER_RECOMMENDATIONS:
        case savActionTypes.SET_SAV_CONTRACT_PARTNER_RECOMMENDATIONS:
        case savaActionTypes.SET_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS: {
            const {serviceAssignmentId, contractPartnerRecommendationDTOs} = action.payload;
            const {recommendedContractPartnerId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            // reorder an array of contract partner recommendations: put the recommended CP at the beginning
            const contractPartnerRecommendations = contractPartnerRecommendationDTOs
                .map(contractPartnerDTO => new ACEPartner().fromDTO(contractPartnerDTO));
            // find the index of the recommended CP
            const recommendedContractPartnerIndex = recommendedContractPartnerId
                ? contractPartnerRecommendations
                    .findIndex(contractPartner => contractPartner.id === recommendedContractPartnerId)
                : null;
            if (recommendedContractPartnerIndex >= 0) {
                // remove recommended contract partner from the array
                const recommendedContractPartner = contractPartnerRecommendations
                    .splice(recommendedContractPartnerIndex, 1)[0];
                if (recommendedContractPartner) {
                    // put recommended contract partner at the beginning
                    contractPartnerRecommendations.splice(0, 0, recommendedContractPartner);
                }
            }
            serviceAssignment.contractPartnerRecommendations = contractPartnerRecommendations
                .map((contractPartner, idx) => ({...contractPartner, rank: idx + 1}));
            serviceAssignment.recommendedContractPartnerId = recommendedContractPartnerId;
            break;
        }

        case savActionTypes.SET_SAV_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT:
        case savaActionTypes.SET_SAVA_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT:
        case sapActionTypes.SET_SAP_ACTIVE_CONTRACT_PARTNER_RECOMMENDATIONS_SNAPSHOT: {
            const {serviceAssignmentId, contractPartnerRecommendations = []} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            if (serviceAssignment && contractPartnerRecommendations) {
                serviceAssignment.activeContractPartnerRecommendationsSnapshot = contractPartnerRecommendations;
            }
            break;
        }

        case serviceAssignmentActionTypes.STORE_RENTAL_CAR_ACE_PARTNERS: {
            const {acePartnerDTOs} = action.payload;

            acePartnerDTOs.forEach(acePartnerDTO => {
                const acePartnerId = acePartnerDTO.id;
                const acePartner = draft.rentalCarACEPartners[acePartnerId];

                if (!acePartner) {
                    draft.rentalCarACEPartners[acePartnerId] = new ACEPartner().fromDTO(acePartnerDTO);
                    return;
                }

                draft.rentalCarACEPartners[acePartnerId] = acePartner.fromDTO(acePartnerDTO);
            });
            break;
        }

        case serviceAssignmentActionTypes.STORE_SARC_ACE_PARTNER_STATIONS: {
            const {serviceProviderId, rentalCarStationDTOs} = action.payload;
            const acePartner = draft.rentalCarACEPartners[serviceProviderId];
            acePartner.rentalCarStations = {};

            rentalCarStationDTOs.forEach(rentalCarStationDTO => {
                const stationId = rentalCarStationDTO.id;
                acePartner.rentalCarStations[stationId] = new RentalCarStation().fromDTO(rentalCarStationDTO);
            });

            break;
        }

        case serviceAssignmentActionTypes.STORE_RENTAL_CAR_ACE_PARTNER_SIPP_CODES: {
            const {serviceProviderId, acePartnerSippCodeDTOs} = action.payload;
            const acePartner = draft.rentalCarACEPartners[serviceProviderId];
            acePartner.rentalCarSIPPCodes = [];

            acePartnerSippCodeDTOs.forEach(acePartnerSippCodeDTO => {
                acePartner.rentalCarSIPPCodes.push(acePartnerSippCodeDTO);
            });

            break;
        }

        case sarcActionTypes.SET_SARC_RENTAL_CAR_STATION_RECOMMENDATIONS: {
            const {serviceAssignmentId, rentalCarStationRecommendationDTOs} = action.payload;
            const {recommendedRentalCarStationId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.rentalCarStationRecommendations = rentalCarStationRecommendationDTOs
                .map(rentalCarStationDTO => new RentalCarStation().fromDTO(rentalCarStationDTO));
            serviceAssignment.recommendedRentalCarStationId = recommendedRentalCarStationId;
            break;
        }

        case sarcActionTypes.SET_SARC_PROVISIONAL_RENTAL_CAR_STATION: {
            const {serviceAssignmentId, provisionalRentalCarStation} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.provisionalRentalCarStation = provisionalRentalCarStation;
            break;
        }

        case saaActionTypes.SET_SAA_REVERSE_GEOCODING_REFERENCE_POSITION: {
            const {arcGISReverseGeocodingLocationDTO, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            const reverseGeocodingReferencePosition = new Location().fromDTO(arcGISReverseGeocodingLocationDTO);
            serviceAssignment.reverseGeocodingReferencePosition = reverseGeocodingReferencePosition
                .setLocationType(efLocationTypes.SELECT_LOCATION);
            break;
        }

        case saaActionTypes.SET_SAA_ACCOMMODATION_LOCATION_RECOMMENDATIONS: {
            const {serviceAssignmentId, accommodationLocationRecommendationDTOs} = action.payload;
            const {recommendedAccommodationLocationId, updatedAccommodationLocationId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.accommodationLocationRecommendations = accommodationLocationRecommendationDTOs
                .map(accommodationLocationDTO => {
                    const {location, businessContactDetails, name} = serviceAssignment.acePartner || {};
                    if (location?.id === accommodationLocationDTO.locationId) {
                        accommodationLocationDTO.locationName = name || accommodationLocationDTO.locationName;
                        accommodationLocationDTO.phoneNo = businessContactDetails?.phoneNo
                            || accommodationLocationDTO.phoneNo;
                    }
                    return new Location().fromDTO(accommodationLocationDTO);
                });
            serviceAssignment.recommendedAccommodationLocationId = recommendedAccommodationLocationId;
            serviceAssignment.updatedAccommodationLocationId = updatedAccommodationLocationId;
            break;
        }

        case sapActionTypes.STORE_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE: {
            const {arcGISRouteDTO} = action.payload;
            draft.pickupLocationToTowingDestinationRoute = arcGISRouteDTO;
            break;
        }

        case sapActionTypes.SET_SAP_PICKUP_ROUTE_DISTANCE: {
            const {routeDistance, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment['pickupRouteDistance'] = routeDistance;
            break;
        }

        case sapActionTypes.RESET_PICKUP_TO_TOWING_DESTINATION_STATIC_ROUTE: {
            draft.pickupLocationToTowingDestinationRoute = {};
            break;
        }

        case serviceAssignmentActionTypes.SET_SERVICE_ASSIGNMENT_QM_FEEDBACK_DRAFTS: {
            const {qmFeedbackDraftDTOs, serviceAssignmentId} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            if (serviceAssignment) {
                const qmFeedbackDrafts = qmFeedbackDraftDTOs && qmFeedbackDraftDTOs.filter(qmFeedbackDraftDTO => (
                    qmFeedbackDraftDTO.categories.length > 0
                ));
                serviceAssignment.qmFeedbackDraft = qmFeedbackDrafts && qmFeedbackDrafts.length > 0
                    ? new QualityManagementFeedback().fromDTO(qmFeedbackDrafts[0])
                    : null;

                const qmFeedbackDraftPlaceholder = qmFeedbackDraftDTOs
                    && qmFeedbackDraftDTOs.find(qmFeedbackDraftDTO => (
                        qmFeedbackDraftDTO.categories.length === 0
                    ));
                serviceAssignment.qmFeedbackDraftPlaceholder = qmFeedbackDraftPlaceholder
                    ? new QualityManagementFeedback().fromDTO(qmFeedbackDraftPlaceholder)
                    : null;
            }
            break;
        }

        case serviceAssignmentActionTypes.STORE_SERVICE_PROVIDERS: {
            const {acePartnerDTOs} = action.payload;

            acePartnerDTOs.forEach(acePartnerDTO => {
                const acePartnerId = acePartnerDTO.id;
                const acePartner = draft.serviceProviders[acePartnerId];

                if (!acePartner) {
                    draft.serviceProviders[acePartnerId] = new ACEPartner()
                        .fromDTO(acePartnerDTO);
                    return;
                }

                draft.serviceProviders[acePartnerId] = acePartner.fromDTO(acePartnerDTO);
            });
            break;
        }

        case serviceAssignmentActionTypes.RESET_SERVICE_PROVIDERS: {
            draft.serviceProviders = {};
            draft.serviceProviderRecommendationsPersistenceState = persistenceStates.PENDING;
            break;
        }

        case samaActionTypes.SET_SAMA_DOCTOR_LOCATION_RECOMMENDATIONS: {
            const {serviceAssignmentId, doctorLocationRecommendationDTOs} = action.payload;
            const serviceAssignment = draft.serviceAssignments[serviceAssignmentId];
            serviceAssignment.doctorLocationRecommendations = doctorLocationRecommendationDTOs
                .map(doctorLocationRecommendationDTO => new Location().fromDTO(doctorLocationRecommendationDTO));
            break;
        }

        case serviceAssignmentActionTypes.SET_ERROR_CODE: {
            const {errorCode} = action.payload;
            draft.errorCode = errorCode;
            break;
        }

        case serviceAssignmentActionTypes.RESET_ERROR_CODE: {
            draft.errorCode = null;
            break;
        }

        case serviceAssignmentActionTypes.STORE_HAS_TO_RESET_ASSIGNMENT_SELECTION: {
            const {hasToResetAttachmentSelection} = action.payload;
            draft.hasToResetAttachmentSelection = hasToResetAttachmentSelection;
            break;
        }

        case samaActionTypes.STORE_SAMA_SERVICE_PROVIDERS: {
            const {medicalAdviceServiceProviders} = action.payload;
            draft.medicalAdviceServiceProviders = medicalAdviceServiceProviders;
            break;
        }

        case sapActionTypes.SET_SAP_CP_RECOMMENDATIONS_PERSISTENCE_STATE:
        case savActionTypes.SET_SAV_CP_RECOMMENDATIONS_PERSISTENCE_STATE:
        case savaActionTypes.SET_SAVA_CP_RECOMMENDATIONS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.contractPartnerRecommendationsPersistenceState = persistenceState;
            break;
        }

        case serviceAssignmentActionTypes.SET_SERVICE_PROVIDER_SEARCH_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.serviceProviderRecommendationsPersistenceState = persistenceState;
            break;
        }

        case serviceAssignmentActionTypes.STORE_BUDGETS_BY_SERVICE_TYPE: {
            const {budgetDTOs} = action.payload;
            const budget = budgetDTOs[0];
            if (budget && budget.serviceType) {
                draft.serviceAssignmentBudgets[budget.serviceType] = budget;
            }
            break;
        }

        case serviceAssignmentActionTypes.STORE_BUDGET_MAX_DURATIONS: {
            const {budgetDTOs} = action.payload;
            const budget = budgetDTOs[0];
            if (budget && budget.serviceType) {
                draft.serviceAssignmentBudgetMaxDurations[budget.serviceType] = budget;
            }
            break;
        }

        case sapActionTypes.STORE_PICKUP_PRICES: {
            const {pickupPriceDTOs, surchargeRateDTOs} = action.payload;
            const sortedPricesByDistance = pickupPriceDTOs.sort((a, b) => a.distanceFrom - b.distanceFrom);
            const mediumWeightSurchargeRate = surchargeRateDTOs.find(surchargeRate => (
                surchargeRate.surchargeType === apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_3
            ));
            const heavyWeightSurchargeRate = surchargeRateDTOs.find(surchargeRate => (
                surchargeRate.surchargeType === apsSurchargeRateTypes.VEHICLE_WEIGHT_VALUE_5
            ));

            const pickupPrices = {};
            sortedPricesByDistance.forEach((price, index) => {
                pickupPrices[index + 1] = {
                    costPerLightWeightThreshold: price.value,
                    costPerMediumWeightThreshold: price.value + (mediumWeightSurchargeRate
                        ? (mediumWeightSurchargeRate.value / 100) * price.value
                        : 0),
                    costPerHeavyWeightThreshold: price.value + (heavyWeightSurchargeRate
                        ? (heavyWeightSurchargeRate.value / 100) * price.value
                        : 0),
                    distanceFrom: price.distanceFrom,
                    distanceTo: price.distanceTo,
                };
            });
            draft.pickupPrices = pickupPrices;
            break;
        }

        default:
        //     no-op
    }
}, initialState);

export default serviceAssignmentReducer;
